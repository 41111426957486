import * as React from 'react'
import { TwitchPlayerNonInteractive } from 'react-twitch-embed'
import './styles.css'

const PTwitchView = ({ key, channel,width, height }) => {
  // let showChat = useIsStreaming(channel)
  const [isOnline, setOnline] = React.useState(false)
  const player = React.createRef()
  const stopPlaying = () => player.current?.pause()

  return (
    <TwitchPlayerNonInteractive
      className={`w-[${width}px] h-[${height}px]`}
      key={key}
      channel={channel}
      onOnline={() => setOnline(true)}
      onOffline={() => setOnline(false)}
      onReady={(p) => {
        player.current = p
      }}
    />

  )
}
export default PTwitchView;

