import * as React from 'react'
import { TwitchPlayerNonInteractive } from 'react-twitch-embed'
import './styles.css'

const TwitchView = ({ key, channel }) => {
  // let showChat = useIsStreaming(channel)
  const [isOnline, setOnline] = React.useState(false)
  const player = React.createRef()
  const stopPlaying = () => player.current?.pause()

  return (
    <TwitchPlayerNonInteractive
      className='md:w-[350px] md:h-[248px] w-[322px] h-[230px] '
      key={key}
      channel={channel}
      onOnline={() => setOnline(true)}
      onOffline={() => setOnline(false)}
      onReady={(p) => {
        player.current = p
      }}
    />

  )
}
export default TwitchView;

