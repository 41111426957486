import React from 'react';
import { Box, Flex, Icon, Divider } from '@chakra-ui/react';
import { FiCamera, FiClock, FiUser, FiLogOut } from 'react-icons/fi';
import { Gamepad } from '@styled-icons/fa-solid/Gamepad';
import { useNavigate } from 'react-router-dom';
import LogoSection from "../../components/Logo";
import Bet from './bet';
import Tournament from './tournament';
import Profile from './profile';
import LiveBetting from './live';

const LinkItems = [
    { name: 'Live', icon: FiCamera, link: '/live' },
    { name: 'Current bets', icon: FiClock, link: '/bet' },
    { name: 'Tournament', icon: Gamepad, link: '/tournament' },
    { name: 'Profile', icon: FiUser, link: '/profile' },
];

const Dashboard = ({ index }) => {
    const navigate = useNavigate()
    const Navbar = () => {
        return (
            <div
                className="md:hidden fixed font-aeonik bottom-0 w-full border-t-[2px] p-[14px] py-[10px] px-5 border-[#132236] bg-[#071018] flex justify-between text-xl z-10"
            >
                {LinkItems.map((link) => (
                    <Box
                        style={{ textDecoration: 'none' }}
                        _focus={{ boxShadow: 'none' }}
                        key={link.name}
                    >
                        <Flex
                            align="center"
                            p="4"
                            mx="4"
                            borderRadius="lg"
                            role="group"
                            cursor="pointer"
                            _hover={{
                                bg: 'rgba(255, 255, 255, 0.20)',
                                color: 'black',
                            }}
                            onClick={() => navigate(link.link)}
                        >
                            {link.icon && (
                                <Icon
                                    mr="4"
                                    fontSize="24"
                                    _groupHover={{
                                        color: 'rgba(205, 187, 255, 0.5)',
                                    }}
                                    color={'rgba(205, 187, 255, 0.5)'}
                                    as={link.icon}
                                />
                            )}
                        </Flex>
                    </Box>
                ))}
            </div>
        )
    }
    const logout = () => {
        navigate('/')
    }
    let ProComponent
    if (index === 1) ProComponent = <LiveBetting />;
    else if (index === 2) ProComponent = <Profile />;
    else if (index === 3) ProComponent = <Tournament />;
    else if (index === 4) ProComponent = <Bet />;
    return (
        <div className='bg-black '>
            <div className="flex items-center justify-center">
                <div className='flex md:w-[1440px]'>
                    <div className='hidden md:flex md:flex-col flex-row md:h-[1024px] py-8 bg-gray-900 text-white justify-between md:w-[310px]'>
                        <div className='flex flex-col gap-5'>
                            <div className='items-center justify-between px-8'>
                                <LogoSection logoheight={60} logowidth={103} />
                            </div>
                            <div>
                                {LinkItems.map((link) => (
                                    <Box
                                        style={{ textDecoration: 'none' }}
                                        _focus={{ boxShadow: 'none' }}
                                        key={link.name}
                                    >
                                        <Flex
                                            align="center"
                                            p="4"
                                            mx="4"
                                            borderRadius="lg"
                                            role="group"
                                            cursor="pointer"
                                            _hover={{
                                                bg: 'rgba(255, 255, 255, 0.20)',
                                                color: 'black',
                                            }}
                                            onClick={() => navigate(link.link)}
                                        >
                                            {link.icon && (
                                                <Icon
                                                    mr="4"
                                                    fontSize="24"
                                                    _groupHover={{
                                                        color: 'rgba(205, 187, 255, 0.5)',
                                                    }}
                                                    color={'rgba(205, 187, 255, 0.5)'}
                                                    as={link.icon}
                                                />
                                            )}
                                            {link.name}
                                        </Flex>
                                    </Box>
                                ))}
                            </div>
                        </div>
                        <div className='hidden md:flex flex-col gap-[10px]'>
                            <Divider />
                            <Box
                                bottom={0}
                                style={{ textDecoration: 'none' }}
                                _focus={{ boxShadow: 'none' }}
                            >
                                <Flex
                                    align="center"
                                    p="4"
                                    mx="4"
                                    borderRadius="lg"
                                    role="group"
                                    cursor="pointer"
                                    _hover={{
                                        bg: 'rgba(255, 255, 255, 0.20)',
                                        color: 'black',
                                    }}
                                    onClick={() => logout()}
                                >
                                    <div style={{ display: 'flex', gap: '40px' }}>
                                        <div style={{ display: 'flex', gap: '12px' }}>
                                            <img src="./images/Avatar.png" alt="avatar" style={{ objectFit: 'cover', justifyContent: 'center', borderRadius: '50%', width: '40px', height: '40px' }} />
                                            <div>
                                                <p style={{ color: 'white', fontSize: '14px' }}>Mahdiverse</p>
                                                <p style={{ color: 'var(--gray-400, #98A2B3)', fontSize: '14px' }}>0xcd3afb4a35f1</p>
                                            </div>
                                        </div>
                                        <div style={{ padding: '8px' }}>
                                            <FiLogOut size={'20px'} color='red' />
                                        </div>
                                    </div>
                                </Flex>
                            </Box>
                        </div>
                    </div>
                    <div className='md:w-full w-[375px] mb-20'>
                        {ProComponent}
                    </div>
                    {<Navbar />}
                </div>
            </div>
        </div>
    );
};

export default Dashboard;